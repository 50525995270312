import React from "react"
import connect from "react-redux/es/connect/connect"
import {withStyles} from "@material-ui/core"
import Grid from '@material-ui/core/Grid'
import EditIcon from '@material-ui/icons/Edit'
import Typography from "@material-ui/core/es/Typography/Typography"
import IconButton from '@material-ui/core/IconButton'
import SettingsIcon from '@material-ui/icons/Settings'
import moment from "moment-timezone"

import styles from '../style'
import Button from '@material-ui/core/Button'
import ConfirmationDialog from '../../components/reusableComponents/ConfirmationDialog';
import {
  appSettingsFetch,
  appSettingsSave,
  buildingChooserOpen,
  singletonKioskEditFetch,
  kioskDefaultConfigurationEdit,
  updateGeneralSetting,
  entityChooserOpen,
  enablePositiveAttendance,
  enablePPK
} from "redux/admin/Actions"

import FullTextField, {FullTextFieldD} from "common/components/FullTextField"
import {findOneBy} from "redux/utils"
import {timezoneNotSet, TIMEZONES, URL_ADMIN_ROOT, SECURITY_ROLE_SUPER_ADMIN} from 'redux/constants'
import AddYearEntityChooserDialog from "../../components/AddYearEntityChooserDialog"

/*
import {ToggleButton} from "../../components/ToggleButton";
import DoneIcon from "@material-ui/icons/Done";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
*/
const timezones = [timezoneNotSet].concat(TIMEZONES)
const POSITIVE_ATTENDANCE = 'positiveAttendance'
const PPK = 'ppk'

class GeneralSettings extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isDirty:false,
      isPositiveAttendanceDialogOpen: false,
      isPPKDialogOpen: false
    }
    this.updateGeneralSetting = this.updateGeneralSetting.bind(this)
  }

  isDirty(val='x') {
    if(val === 'x') {
      return this.state.isDirty
    }
    this.setState({isDirty:!!val})
  }

  updateGeneralSetting(payload) {
    this.props.updateGeneralSetting(payload)
    if(!this.isDirty()) {
      this.isDirty(true)
    }
  }

  handleOpenDialog = (dialogType) => {
    if (dialogType === POSITIVE_ATTENDANCE) {
      this.setState({ isPositiveAttendanceDialogOpen: true });
    } else if (dialogType === PPK) {
      this.setState({ isPPKDialogOpen: true });
    }
  };

  handleCloseDialog = (dialogType) => {
    if (dialogType === POSITIVE_ATTENDANCE) {
      this.setState({ isPositiveAttendanceDialogOpen: false });
    } else if (dialogType === PPK) {
      this.setState({ isPPKDialogOpen: false });
    }
  };

  handleConfirmDialog = (dialogType) => {
    const { enablePositiveAttendance, enablePPK } = this.props;
    if (dialogType === POSITIVE_ATTENDANCE) {
      enablePositiveAttendance();
      this.setState({ isPositiveAttendanceDialogOpen: false });
    } else if (dialogType === PPK) {
      enablePPK();
      this.setState({ isPPKDialogOpen: false });
    }
  };


  render() {

    let {
      atUserVendorIdLengthMax,
      atUserVendorIdLengthMin,
      buildingChooserOpen,
      entityChooserOpen,
      classes,
      singletonKioskEditFetch,
      jsbTimezone,
      jsbVendor,
      kioskDefaultConfigurationEdit,
      name,
      serverHostUrl,
      updateGeneralSetting,
      loggedInUser,
      positiveAttendanceIsEnabled,
      ppkIsEnabled
    } = this.props

    const { isPositiveAttendanceDialogOpen, isPPKDialogOpen } = this.state;

    const isSuperAdmin = loggedInUser.securityRoles.includes(SECURITY_ROLE_SUPER_ADMIN);

    atUserVendorIdLengthMin = atUserVendorIdLengthMin + ''
    atUserVendorIdLengthMax = atUserVendorIdLengthMax + ''
    const tz = findOneBy(timezones, 'val', jsbTimezone.val)? findOneBy(timezones, 'val', jsbTimezone.val) : {displayName:'none'}
    const now = (tz.displayName === 'none')? moment() : moment().tz(jsbTimezone.val)
    const districtName = jsbVendor? jsbVendor.Name : ''
    
    const isPositiveAttendanceAfterInstallAllowed = (window.REACT_APP_ALLOW_POSITIVE_ATTENDANCE_AFTER_INSTALL || "").toUpperCase() === "TRUE";
    const isPPKAfterInstallAllowed = (window.REACT_APP_ALLOW_PPK_AFTER_INSTALL || "").toUpperCase() === "TRUE";

    const enablePositiveAttendanceButtonColor = positiveAttendanceIsEnabled ? 'secondary' : 'primary';
    const positiveAttendaceToggleAction = positiveAttendanceIsEnabled ? 'disable' : 'enable'
    const capitalizedAction = positiveAttendaceToggleAction.charAt(0).toUpperCase() + positiveAttendaceToggleAction.slice(1);
    const enablePositiveAttendanceButtonText =`${capitalizedAction} Positive Attendance`;
    const positiveAttendaceToggleDialogTitle = `${capitalizedAction} Positive Attendance`;
    const positiveAttendaceToggleDialogContentPrimary = `Are you sure you want to ${positiveAttendaceToggleAction} Positive Attendance globally?`
    const positiveAttendaceToggleDialogContentSecondary = positiveAttendaceToggleAction === 'enable' ?
      `After enabling Positive Attendance globally, you will need to configure positive attendance for each entity on the Period Rules page. Then, to start attendance monitoring, run the Attendance Monitor job on the Synchronization page.` : '';

    const enablePPKButtonColor = ppkIsEnabled ? 'secondary' : 'primary';
    const ppkToggleAction = ppkIsEnabled ? 'disable' : 'enable'
    const capitalizedPPKAction = ppkToggleAction.charAt(0).toUpperCase() + ppkToggleAction.slice(1);
    const enablePPKButtonText = `${capitalizedPPKAction} Pass Plus Kiosks (PPK)`;
    const ppkToggleDialogTitle = `${capitalizedPPKAction} PPK`;
    const ppkToggleDialogContentPrimary = `Are you sure you want to ${ppkToggleAction} Pass Plus Kiosks globally?`
    const ppkToggleDialogContentSecondary = ppkToggleAction === 'enable' ?
      `After enabling PPK devices, you can create and configure PPK devices at the selected location page. Then, to start PPK listening, run the PPK listener job on the Synchronization page.` : '';

    return (
      <div className={classes.root}>
        <Grid container>
          <Grid item className={classes.gridItem} xs={12}>
            <Typography variant="h6" >General Settings</Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item className={classes.gridItem} xs={12} sm={6}>
            <FullTextField
              id="setting-orgName"
              label="Your Organization Name"
              value={name}
              propertyName='name'
              dispatchUpdate={updateGeneralSetting}
              margin="normal"
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item className={classes.gridItem} xs={12} sm={6}>
            <FullTextFieldD
              label="Server Url"
              value={serverHostUrl}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item className={classes.gridItem} xs={12} sm={6}>
            <FullTextFieldD
              label='District'
              value={districtName + ''}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item className={classes.gridItem} xs={12} sm={6}>
            <FullTextFieldD
              label='Timezone'
              value={tz.displayName + ' ' + now.format('h:mmA')}
            />
          </Grid>
        </Grid>
        <Grid container className={classes.firstRow} style={{maxWidth:850}}>
          <Grid item className={classes.gridItem} xs={12}>
            <Typography variant="h6" >Kiosk Related</Typography>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <Grid container>
              <Grid item className={classes.gridItem} xs={11}>
                <Typography>Kiosk Defaults <IconButton onClick={()=>kioskDefaultConfigurationEdit({parentType:'facility', parentId:0})}><EditIcon /></IconButton></Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <Grid container>
              <Grid item className={classes.gridItem} xs={11}>
                <Typography>Virtual Kiosk
                <IconButton onClick={singletonKioskEditFetch}><EditIcon /></IconButton>
                  {/*<ToggleButton
                    onClick={() => true
                    }
                    title='Enable/Disable Virtual Kiosk'
                    iconMap={{ 'true': DoneIcon, 'false': HighlightOffIcon }}
                    iconKey={'true'}
                  /> */}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          { isSuperAdmin && 
            <Grid item xs={12} md={6} lg={3}>
              <Grid container>
                <Grid item className={classes.gridItem} xs={11}>
                  <Typography>Active Buildings <IconButton onClick={buildingChooserOpen}><EditIcon /></IconButton></Typography>
                </Grid>
              </Grid>
            </Grid>
          }
          { isSuperAdmin &&  
            <Grid item xs={12} md={6} lg={3}>
              <Grid container>
                <Grid item className={classes.gridItem} xs={11}>
                  <Typography>Add Entities
                    <IconButton onClick={entityChooserOpen}><EditIcon /></IconButton>
                  </Typography>

                  <AddYearEntityChooserDialog/>
                </Grid>
              </Grid>
          </Grid> }       
        </Grid>
        <Grid container className={classes.firstRow}>
          <Grid item className={classes.gridItem} xs={12}>
            <Typography variant="h6">Student ID code length</Typography>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Grid container>
              <Grid item className={classes.gridItem} xs={6} >
                <FullTextField
                  id="atUserVendorIdLengthMin"
                  label="Min"
                  value={atUserVendorIdLengthMin}
                  propertyName='atUserVendorIdLengthMin'
                  dispatchUpdate={this.updateGeneralSetting}
                  margin="normal"
                />
              </Grid>
              <Grid item className={classes.gridItem} xs={6}>
                <FullTextField
                  id="atUserVendorIdLengthMax"
                  label="Max"
                  value={atUserVendorIdLengthMax}
                  propertyName='atUserVendorIdLengthMax'
                  dispatchUpdate={this.updateGeneralSetting}
                  margin="normal"
                />
              </Grid>
            </Grid>
          </Grid>          
        </Grid>
        <Grid item className={classes.copyrightCaption} xs={12}>
          <Typography variant="body2" color="textSecondary">Skyward, the Skyward logo, and Qmlativ are registered trademarks of Skyward, Inc.</Typography>
          <Typography variant="body2" color="textSecondary">SchoolTRAK and the SchoolTRAK logo are registered trademarks of School Technology Associates, Inc.</Typography>
        </Grid>
        <Grid container className={classes.firstRow}>
          {(isPositiveAttendanceAfterInstallAllowed || isPPKAfterInstallAllowed) && isSuperAdmin && (
            <>
            <Grid style={{ marginBottom: 10 }} item className={classes.gridItem} xs={12}>
              <Typography variant="h6">License Features</Typography>
            </Grid>
          </>
          )}
          <Grid item className={classes.gridItem} xs={11}>
            {isPositiveAttendanceAfterInstallAllowed && isSuperAdmin && (
              <>
                <Button style={{ marginRight: 15, height: 55 }} className={classes.button}
                  onClick={() => this.handleOpenDialog(POSITIVE_ATTENDANCE)}
                  color={enablePositiveAttendanceButtonColor} variant='outlined'>
                  {enablePositiveAttendanceButtonText}
                </Button>
                <ConfirmationDialog
                  open={isPositiveAttendanceDialogOpen}
                  onClose={() => this.handleCloseDialog(POSITIVE_ATTENDANCE)}
                  onConfirm={() => this.handleConfirmDialog(POSITIVE_ATTENDANCE)}
                  title={positiveAttendaceToggleDialogTitle}
                  contentPrimary={positiveAttendaceToggleDialogContentPrimary}
                  contentSecondary={positiveAttendaceToggleDialogContentSecondary}
                />
              </>
            )}
            {isPPKAfterInstallAllowed && isSuperAdmin && (
              <>
                <Button style={{ marginRight: 15, height: 55 }} className={classes.button}
                  onClick={() => this.handleOpenDialog(PPK)}
                  color={enablePPKButtonColor} variant='outlined'>
                  {enablePPKButtonText}
                </Button>
                <ConfirmationDialog
                  open={isPPKDialogOpen}
                  onClose={() => this.handleCloseDialog(PPK)}
                  onConfirm={() => this.handleConfirmDialog(PPK)}
                  title={ppkToggleDialogTitle}
                  contentPrimary={ppkToggleDialogContentPrimary}
                  contentSecondary={ppkToggleDialogContentSecondary}
                />
              </>)}
          </Grid>
        </Grid>
      </div>
    )

  }
}

const mapStateToProps = state => ({
  ...state.admin.settings,
  loggedInUser: state.admin.loggedInUser,
  positiveAttendanceIsEnabled: state.admin.positiveAttendanceIsEnabled,
  ppkIsEnabled: state.admin.ppkIsEnabled,
});

const mapDispatchToProps = {
  buildingChooserOpen,
  updateGeneralSetting,
  appSettingsFetch,
  appSettingsSave,
  kioskDefaultConfigurationEdit,
  singletonKioskEditFetch,
  entityChooserOpen,
  enablePositiveAttendance,
  enablePPK
}

export const SettingsPage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles, { withTheme: true })(GeneralSettings))

export const settingsPage = {
  icon: SettingsIcon,
  component: SettingsPage,
  name: 'GeneralSettings',
  to: `${URL_ADMIN_ROOT}/:facilityId?/settings`
}

export default SettingsPage