export default theme => ({
  confirmationContentPrimary: {
    fontSize: 18,
    marginTop: 15,
    textAlign: 'center',
    width: '100%',
  },
  confirmationContentSecondary: {
    fontSize: 14,
    marginTop: 15,
    width: '100%',
    textAlign: 'left',
  }
})
