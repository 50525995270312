import React from 'react';
import DialogTitle from 'common/components/dialog/DialogTitle';
import DialogContent from 'common/components/dialog/DialogContent';
import DialogActions from 'common/components/dialog/DialogActions';
import { Dialog, Button, Typography, withStyles } from '@material-ui/core';
import styles from './style';

const ConfirmationDialog = ({ open, onClose, onConfirm, title, contentPrimary, contentSecondary, classes }) => {
  contentSecondary = contentSecondary || '';

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle id="customized-dialog-title" onClose={onClose}>{title}</DialogTitle>

      <DialogContent>
        <Typography className={classes.confirmationContentPrimary}>
          {contentPrimary}
        </Typography>

        <Typography className={classes.confirmationContentSecondary}>
          {contentSecondary.split('\n').map((line, index) => (
            <span key={index}>
              {line}
              <br />
            </span>
          ))}
        </Typography>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={onConfirm} color="primary" autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles, { withTheme: true })(ConfirmationDialog);