export const ADMIN_FETCH_RICH_LOCATION_BY_ID = 'ADMIN_FETCH_RICH_LOCATION_BY_ID'
export const ADMIN_FETCH_RICH_STUDENT_BY_ID = 'ADMIN_FETCH_RICH_STUDENT_BY_ID'
export const ADMIN_SET_LOCATION_PAGE_INITAL_DATA = 'ADMIN_SET_LOCATION_PAGE_INITAL_DATA'
export const ADMIN_SET_LOCATION_PAGE_KIOSKS = 'ADMIN_SET_LOCATION_PAGE_KIOSKS'
export const ADMIN_EDIT_LOCATION_SETTINGS = 'ADMIN_EDIT_LOCATION_SETTINGS'

export const ADMIN_AT_RECORD_EDIT = 'ADMIN_AT_RECORD_EDIT'
export const ADMIN_AT_RECORD_EDIT_DONE = 'ADMIN_AT_RECORD_EDIT_DONE'
export const ADMIN_AT_RECORD_EDIT_SAVE = 'ADMIN_AT_RECORD_EDIT_SAVE'
export const ADMIN_AT_RECORD_EDIT_UPDATE_PROPERTY = 'ADMIN_AT_RECORD_EDIT_UPDATE_PROPERTY'

export const ADMIN_BELL_RULES_EDIT = 'ADMIN_BELL_RULES_EDIT'
export const ADMIN_BELL_RULES_EDIT_DONE = 'ADMIN_BELL_RULES_EDIT_DONE'
export const ADMIN_BELL_RULES_EDIT_SAVE = 'ADMIN_BELL_RULES_EDIT_SAVE'
export const ADMIN_BELL_RULES_EDIT_SAVE_RESPONSE = 'ADMIN_BELL_RULES_EDIT_SAVE_RESPONSE'
export const ADMIN_BELL_RULES_EDIT_UPDATE_PROPERTY = 'ADMIN_BELL_RULES_EDIT_UPDATE_PROPERTY'

export const ADMIN_BELL_SCHEDULE_FETCH_BY_FACILITY_ID = 'ADMIN_BELL_SCHEDULE_FETCH_BY_FACILITY_ID'
export const ADMIN_BELL_SCHEDULE_FETCH_BY_FACILITY_ID_RESPONSE = 'ADMIN_BELL_SCHEDULE_FETCH_BY_FACILITY_ID_RESPONSE'

export const ADMIN_CHANGE_KIOSK_SETTING = 'ADMIN_CHANGE_KIOSK_SETTING'
export const ADMIN_CLOSE_KIOSK_EDITOR = 'ADMIN_CLOSE_KIOSK_EDITOR'
export const ADMIN_APP_SETTINGS_FETCH = 'ADMIN_APP_SETTINGS_FETCH'
export const ADMIN_APP_SETTINGS_FETCH_RESPONSE = 'ADMIN_APP_SETTINGS_FETCH_RESPONSE'
export const ADMIN_APP_SETTINGS_SAVE = 'ADMIN_APP_SETTINGS_SAVE'
export const ADMIN_APP_SETTINGS_SAVE_RESPONSE = 'ADMIN_APP_SETTINGS_SAVE_RESPONSE'
export const ADMIN_SAVE_KIOSK_SETTINGS = 'ADMIN_SAVE_KIOSK_SETTINGS'
export const ADMIN_SAVE_KIOSK_SETTINGS_RESPONSE = 'ADMIN_SAVE_KIOSK_SETTINGS_RESPONSE'
export const ADMIN_SAVE_LOCATION_SETTINGS = 'ADMIN_SAVE_LOCATION_SETTINGS'
export const ADMIN_SAVE_LOCATION_SETTINGS_RESPONSE = 'ADMIN_SAVE_LOCATION_SETTINGS_RESPONSE'
export const ADMIN_SEARCH_STUDENTS = 'ADMIN_SEARCH_STUDENTS'
export const ADMIN_SEARCH_LOCATOR_STUDENTS = 'ADMIN_SEARCH_LOCATOR_STUDENTS'
export const ADMIN_SELECT_LOCATOR_STUDENT = 'ADMIN_SELECT_LOCATOR_STUDENT'
export const ADMIN_SET_LOCATOR_STUDENT_INFO = 'ADMIN_SET_LOCATOR_STUDENT_INFO'
export const ADMIN_REQUEST_SYNC_QUEUES = 'ADMIN_REQUEST_SYNC_QUEUES'
export const ADMIN_SET_SYNC_QUEUES_RESPONSE = 'ADMIN_SET_SYNC_QUEUES_RESPONSE'
export const ADMIN_SET_LOCATOR_SCHEDULE_PICKER_DATE = 'ADMIN_SET_LOCATOR_SCHEDULE_PICKER_DATE'
export const ADMIN_SET_LOCATOR_SCHEDULE_PERIODS = 'ADMIN_SET_LOCATOR_SCHEDULE_PERIODS'
export const ADMIN_SEARCH_STUDENTS_RESPONSE = 'ADMIN_SEARCH_STUDENTS_RESPONSE'
export const ADMIN_SEARCH_LOCATOR_STUDENTS_RESPONSE = 'ADMIN_SEARCH_LOCATOR_STUDENTS_RESPONSE'
export const ADMIN_RESET_SEARCH_LOCATOR_STUDENTS_RESPONSE = 'ADMIN_RESET_SEARCH_LOCATOR_STUDENTS_RESPONSE'
export const ADMIN_SNACK_MESSAGE_HIDE = 'ADMIN_SNACK_MESSAGE_HIDE'
export const ADMIN_SNACK_MESSAGE_SHOW = 'ADMIN_SNACK_MESSAGE_SHOW'
export const ADMIN_STUDENTS_SEARCH_STATUS_MESSAGE = 'ADMIN_STUDENTS_SEARCH_STATUS_MESSAGE'
export const ADMIN_DELETE_KIOSK = 'ADMIN_DELETE_KIOSK'
export const ADMIN_SAVE_STUDENT = 'ADMIN_SAVE_STUDENT'
export const ADMIN_COMING_SOON = 'ADMIN_COMING_SOON'

export const ADMIN_MESSAGING_FETCH = 'ADMIN_MESSAGING_FETCH'
export const ADMIN_MESSAGING_FETCH_RESPONSE = 'ADMIN_MESSAGING_FETCH_RESPONSE'
export const ADMIN_MESSAGING_EDIT_UPDATE_CONTENT = 'ADMIN_MESSAGING_EDIT_UPDATE_CONTENT'
export const ADMIN_MESSAGING_EDIT_UPDATE_CONTENT_STYLE = 'ADMIN_MESSAGING_EDIT_UPDATE_CONTENT_STYLE'
export const ADMIN_MESSAGING_EDIT_CHOOSE_CONTENT_MESSAGE = 'ADMIN_MESSAGING_EDIT_CHOOSE_CONTENT_MESSAGE'

export const ADMIN_MESSAGING_EDIT_SAVE_CONTENT_RESPONSE = 'ADMIN_MESSAGING_EDIT_SAVE_CONTENT_RESPONSE'
export const ADMIN_UPDATE_GENERAL_SETTING = 'ADMIN_UPDATE_GENERAL_SETTING'

export const ADMIN_KIOSK_DEFAULT_CONFIGURATION_EDIT = 'ADMIN_KIOSK_DEFAULT_CONFIGURATION_EDIT'
export const ADMIN_KIOSK_DEFAULT_CONFIGURATION_EDIT_RESPONSE = 'ADMIN_KIOSK_DEFAULT_CONFIGURATION_EDIT_RESPONSE'
export const ADMIN_KIOSK_DEFAULT_CONFIGURATION_EDIT_SAVE = 'ADMIN_KIOSK_DEFAULT_CONFIGURATION_EDIT_SAVE'
export const ADMIN_KIOSK_DEFAULT_CONFIGURATION_FORCE_PUSH = 'ADMIN_KIOSK_DEFAULT_CONFIGURATION_FORCE_PUSHF'

export const ADMIN_KIOSK_DEFAULT_CONFIGURATION_PROP_SET = 'ADMIN_KIOSK_DEFAULT_CONFIGURATION_PROP_SET'
export const ADMIN_KIOSK_DEFAULT_CONFIGURATION_PROP_SET_AND_SAVE = 'ADMIN_KIOSK_DEFAULT_CONFIGURATION_PROP_SET_AND_SAVE'
export const ADMIN_KIOSK_DEFAULT_CONFIGURATION_PROP_SET_CHANGEABLE = 'ADMIN_KIOSK_DEFAULT_CONFIGURATION_PROP_SET_CHANGEABLE'

export const ADMIN_LOGIN_SHOW_UI = 'ADMIN_LOGIN_SHOW_UI'
export const ADMIN_LOGIN_SEND = 'ADMIN_LOGIN_SEND'
export const ADMIN_LOGOUT_SEND = 'ADMIN_LOGOUT_SEND'

export const ADMIN_USER_ACCOUNT_SEARCH_RESPONSE = 'ADMIN_USER_ACCOUNT_SEARCH_RESPONSE'
export const ADMIN_USER_ACCOUNT_SEARCH = 'ADMIN_USER_ACCOUNT_SEARCH'

export const ADMIN_USER_ACCOUNT_DELETE = 'ADMIN_USER_ACCOUNT_DELETE'
export const ADMIN_USER_ACCOUNT_EDIT = 'ADMIN_USER_ACCOUNT_EDIT'
export const ADMIN_USER_ACCOUNT_EDIT_UPDATE_PROPERTY = 'ADMIN_USER_ACCOUNT_EDIT_UPDATE_PROPERTY'
export const ADMIN_USER_ACCOUNT_EDIT_DONE = 'ADMIN_USER_ACCOUNT_EDIT_DONE'
export const ADMIN_USER_ACCOUNT_EDIT_SAVE = 'ADMIN_USER_ACCOUNT_EDIT_SAVE'
export const ADMIN_USER_ACCOUNT_EDIT_SAVE_RESPONSE = 'ADMIN_USER_ACCOUNT_EDIT_SAVE_RESPONSE'

const action = (type, payload) => {
  return {type, payload}
}


export const atRecordEdit = (payload) => action(ADMIN_AT_RECORD_EDIT, payload)
export const atRecordEditDone = () => action(ADMIN_AT_RECORD_EDIT_DONE)
export const atRecordEditSave = () => action(ADMIN_AT_RECORD_EDIT_SAVE)

export const AT_RECORD_DELETE = 'ADMIN_AT_RECORD_DELETE'
export const atRecordDelete = () => action(AT_RECORD_DELETE)

export const atRecordEditUpdateProperty = (payload) => action(ADMIN_AT_RECORD_EDIT_UPDATE_PROPERTY, payload)

export const bellRulesEdit = (payload) => action(ADMIN_BELL_RULES_EDIT, payload)
export const bellRulesEditDone = () => action(ADMIN_BELL_RULES_EDIT_DONE)
export const bellRulesEditSave = () => action(ADMIN_BELL_RULES_EDIT_SAVE)
export const bellRulesEditSaveResponse = (payload) => action(ADMIN_BELL_RULES_EDIT_SAVE_RESPONSE,payload)
export const bellRulesEditUpdateProperty = (payload) => action(ADMIN_BELL_RULES_EDIT_UPDATE_PROPERTY, payload)
export const bellScheduleFetchByFaciltyId = (facilityId) => action(ADMIN_BELL_SCHEDULE_FETCH_BY_FACILITY_ID, facilityId)
export const bellScheduleFetchByFaciltyIdResponse = (payload) => action(ADMIN_BELL_SCHEDULE_FETCH_BY_FACILITY_ID_RESPONSE, payload)

export const fetchRichLocationById = (locationId) => action(ADMIN_FETCH_RICH_LOCATION_BY_ID, locationId)
export const fetchRichStudentById = (studentId) => action(ADMIN_FETCH_RICH_STUDENT_BY_ID, studentId)

export const STUDENT_DETAIL_PAGE_SET_INITIAL_DATA = 'ADMIN_STUDENT_DETAIL_PAGE_SET_INITIAL_DATA'
export const setStudentPageInitialData = (richStudent) => action(STUDENT_DETAIL_PAGE_SET_INITIAL_DATA, richStudent)

export const SET_STUDENT_PAGE_LIST_ATTENDANCE = 'ADMIN_SET_STUDENT_PAGE_LIST_ATTENDANCE'
export const setStudentPageListAttendance = (payload) => action(SET_STUDENT_PAGE_LIST_ATTENDANCE, payload)

export const SET_STUDENT_PAGE_SCHEDULE_ATTENDANCE = 'ADMIN_SET_STUDENT_PAGE_SCHEDULE_ATTENDANCE'
export const setStudentPageScheduleAttendance = (payload) => action(SET_STUDENT_PAGE_SCHEDULE_ATTENDANCE, payload)

export const saveStudent = (student) => action(ADMIN_SAVE_STUDENT, student)
export const setLocationPageInitialData = (location) => action(ADMIN_SET_LOCATION_PAGE_INITAL_DATA, location)

export const FETCH_SCHEDULE_DISPLAY_SETTINGS = 'ADMIN_FETCH_SCHEDULE_DISPLAY_SETTINGS'
export const fetchScheduleDisplaySettings = () => action(FETCH_SCHEDULE_DISPLAY_SETTINGS);

export const SAVE_SCHEDULE_DISPLAY_SETTINGS = 'ADMIN_SAVE_SCHEDULE_DISPLAY_SETTINGS'
export const saveScheduleDisplaySettings = (payload) => action(SAVE_SCHEDULE_DISPLAY_SETTINGS, payload);

export const SET_SCHEDULE_DISPLAY_SETTINGS = 'ADMIN_SET_SCHEDULE_DISPLAY_SETTINGS'
export const setScheduleDisplaySettings = (payload) => action(SET_SCHEDULE_DISPLAY_SETTINGS, payload);

export const deleteKiosk = (kioskId) => {
  return action(ADMIN_DELETE_KIOSK, kioskId)
}

export const changeKioskSetting = (id, propName, value) => {
  return action(ADMIN_CHANGE_KIOSK_SETTING, {
    id, propName, value
  })
}

export const saveKioskSettings = () => {
  return action(ADMIN_SAVE_KIOSK_SETTINGS)
}

export const saveLocationSettings = () => {
  return action(ADMIN_SAVE_LOCATION_SETTINGS)
}

export const kioskDefaultConfigurationPropSetAndSave = (id, propName, value) => {
  return action(ADMIN_KIOSK_DEFAULT_CONFIGURATION_PROP_SET_AND_SAVE, {id, propName, value})
}

export const kioskDefaultConfigurationPropSet = (id, propName, value) =>
  action(ADMIN_KIOSK_DEFAULT_CONFIGURATION_PROP_SET, {id, propName, value})

export const kioskDefaultConfigurationPropSetChangeable = (id, propName, isChangeable) =>
  action(ADMIN_KIOSK_DEFAULT_CONFIGURATION_PROP_SET_CHANGEABLE, {id, propName, isChangeable})


export const STUDENT_DETAIL_PAGE_REPORT_EDIT_UPDATE_PROPERTY = 'ADMIN_STUDENT_DETAIL_PAGE_REPORT_EDIT_UPDATE_PROPERTY'
export const studentDetailPageReportEditUpdateProperty = (payload) =>
  action(STUDENT_DETAIL_PAGE_REPORT_EDIT_UPDATE_PROPERTY, payload)

export const SINGLETON_KIOSK_EDIT_FETCH = 'ADMIN_SINGLETON_KIOSK_EDIT_FETCH'
export const singletonKioskEditFetch = () => {
  return action(SINGLETON_KIOSK_EDIT_FETCH)
}


export const SINGLETON_KIOSK_EDIT_FETCH_RESPONSE = 'ADMIN_SINGLETON_KIOSK_EDIT_FETCH_RESPONSE'
export const singletonKioskEditFetchResponse = (payload) => {
  return action(SINGLETON_KIOSK_EDIT_FETCH_RESPONSE, payload)
}


export const STUDENT_DETAIL_PAGE_REPORT_FETCH_ATTENDANCE_LOG = 'ADMIN_STUDENT_DETAIL_PAGE_REPORT_FETCH_ATTENDANCE_LOG'
export const studentDetailPageReportFetchAttendanceLog = (payload) => {
  return action(STUDENT_DETAIL_PAGE_REPORT_FETCH_ATTENDANCE_LOG, payload)
}

export const STUDENT_DETAIL_PAGE_REPORT_FETCH_ATTENDANCE_LOG_RESPONSE = 'ADMIN_STUDENT_DETAIL_PAGE_REPORT_FETCH_ATTENDANCE_LOG_RESPONSE'
export const studentDetailPageReportFetchAttendanceLogResponse = (payload) => {
  return action(STUDENT_DETAIL_PAGE_REPORT_FETCH_ATTENDANCE_LOG_RESPONSE, payload)
}

export const SET_STUDENT_DETAIL_PAGE_ATTENDANCE_VIEW = 'ADMIN_SET_STUDENT_DETAIL_PAGE_ATTENDANCE_VIEW'
export const setStudentDetailPageAttendanceView = (payload) => {
  return action(SET_STUDENT_DETAIL_PAGE_ATTENDANCE_VIEW, payload)
}

export const STUDENT_DETAIL_TOGGLE_SHOW_REPORTING_ONLY = 'ADMIN_STUDENT_DETAIL_TOGGLE_SHOW_REPORTING_ONLY'
export const studentDetailPageToggleShowReportingOnly = (payload) => {
  return action(STUDENT_DETAIL_TOGGLE_SHOW_REPORTING_ONLY, payload)
}

export const ENTITY_CHOOSER_VALIDATE_SET = 'ADMIN_ENTITY_CHOOSER_VALIDATE_SET'
export const entityChooserValidateSet = (data) => {
  return action(ENTITY_CHOOSER_VALIDATE_SET, data)
}

export const ENTITY_CHOOSER_SET_IS_ENABLED = 'ADMIN_ENTITY_CHOOSER_SET_IS_ENABLED'
export const entityChooserSetIsEnabled = (data) => {
  return action(ENTITY_CHOOSER_SET_IS_ENABLED, data)
}

export const ENTITY_SET_IS_ENABLED_RESPONSE = 'ADMIN_ENTITY_SET_IS_ENABLED_RESPONSE'
export const entitySetIsEnabledResponse = (payload) => {
  return action(ENTITY_SET_IS_ENABLED_RESPONSE, payload)
}

export const ENTITY_CHOOSER_OPEN = 'ADMIN_ENTITY_CHOOSER_OPEN'
export const entityChooserOpen = () => {
  return action(ENTITY_CHOOSER_OPEN)
}

export const ENTITY_CHOOSER_OPEN_RESPONSE = 'ADMIN_ENTITY_CHOOSER_OPEN_RESPONSE'
export const entityChooserOpenResponse = (payload) => {
  return action(ENTITY_CHOOSER_OPEN_RESPONSE, payload)
}

export const SEND_ENTITY_IS_ENABLED = 'ADMIN_SEND_ENTITY_IS_ENABLED'
export const sendEntityIsEnabled = (payload) => {
  return action(SEND_ENTITY_IS_ENABLED, payload)
}

export const closeKioskEditor = () => {
  return action(ADMIN_CLOSE_KIOSK_EDITOR)
}

export const appSettingsFetch = () => {
  return action(ADMIN_APP_SETTINGS_FETCH)
}

export const appSettingsFetchResponse = (data) => {
  return action(ADMIN_APP_SETTINGS_FETCH_RESPONSE, data)
}

export const ADMIN_APP_WORKER_JOB_FETCH_RESPONSE = 'ADMIN_APP_WORKER_JOB_FETCH_RESPONSE'
export const appWorkerJobFetchResponse = (data) => {
  return action(ADMIN_APP_WORKER_JOB_FETCH_RESPONSE, data)
}

export const appSettingsSave = () => {
  return action(ADMIN_APP_SETTINGS_SAVE)
}

export const appSettingsSaveResponse = (data) => {
  return action(ADMIN_APP_SETTINGS_SAVE_RESPONSE, data)
}

export const BUILDING_CHOOSER_DONE = 'ADMIN_BUILDING_CHOOSER_DONE'
export const buildingChooserDone = (data) => {
  return action(BUILDING_CHOOSER_DONE, data)
}

export const BUILDING_CHOOSE_CONFIRM_RESPONSE = 'BUILDING_CHOOSE_CONFIRM_RESPONSE'
export const buildingChooseConfirmResponse = (data) => {
  return action(BUILDING_CHOOSE_CONFIRM_RESPONSE, data)
}

export const LOCATIONS_SYNC_WAIT_RESPONSE = 'LOCATIONS_SYNC_WAIT_RESPONSE'
export const locationsSyncWaitResponse = (data) => {
  return action(LOCATIONS_SYNC_WAIT_RESPONSE, data)
}

export const BUILDING_CHOOSER_CONFIRM = 'ADMIN_BUILDING_CHOOSER_CONFIRM'
export const buildingChooserConfirm = (data) => {
  return action(BUILDING_CHOOSER_CONFIRM, data)
}

export const BUILDING_CHOOSER_OPEN = 'ADMIN_BUILDING_CHOOSER_OPEN'
export const buildingChooserOpen = () => {
  return action(BUILDING_CHOOSER_OPEN)
}

export const BUILDING_CHOOSER_VALIDATE_SET = 'ADMIN_BUILDING_CHOOSER_VALIDATE_SET'
export const buildingChooserValidateSet = (data) => {
  return action(BUILDING_CHOOSER_VALIDATE_SET, data)
}

export const BUILDING_CHOOSER_OPEN_RESPONSE = 'ADMIN_BUILDING_CHOOSER_OPEN_RESPONSE'
export const buildingChooserOpenResponse = (payload) => {
  return action(BUILDING_CHOOSER_OPEN_RESPONSE, payload)
}

export const BUILDING_CHOOSER_SET_IS_ENABLED = 'ADMIN_BUILDING_CHOOSER_SET_IS_ENABLED'
export const buildingChooserSetIsEnabled = (data) => {
  return action(BUILDING_CHOOSER_SET_IS_ENABLED, data)
}

export const FETCH_STUDENT_PAGE_LIST_ATTENDANCE = 'ADMIN_FETCH_STUDENT_PAGE_LIST_ATTENDANCE'
export const fetchStudentPageListAttendance = (data) => {
  return action(FETCH_STUDENT_PAGE_LIST_ATTENDANCE, data)
}

export const FETCH_STUDENT_PAGE_SCHEDULE_ATTENDANCE = 'ADMIN_FETCH_STUDENT_PAGE_SCHEDULE_ATTENDANCE'
export const fetchStudentPageScheduleAttendance = (data) => {
  return action(FETCH_STUDENT_PAGE_SCHEDULE_ATTENDANCE, data)
}

export const saveKioskSettingsResponse = (data) => {
  return action(ADMIN_SAVE_KIOSK_SETTINGS_RESPONSE, data)
}

export const saveLocationSettingsResponse = (data) => {
  return action(ADMIN_SAVE_LOCATION_SETTINGS_RESPONSE, data)
}

export const ADMIN_SEARCH_LOCATIONS_BY = 'ADMIN_SEARCH_LOCATIONS_BY'
export const searchLocationsBy = (searchParams) => {
  return action (ADMIN_SEARCH_LOCATIONS_BY, searchParams)
}

export const SEARCH_MESSAGING_CONTENT_BY = 'ADMIN_SEARCH_MESSAGING_CONTENT_BY'
export const searchMessagingContentBy = (searchParams) => {
  return action (SEARCH_MESSAGING_CONTENT_BY, searchParams)
}

export const SET_URL_SEARCH_PROPERTY = 'ADMIN_SET_URL_SEARCH_PROPERTY'
export const setUrlSearchProperty = (searchParams) => {
  return action (SET_URL_SEARCH_PROPERTY, searchParams)
}

export const SET_URL_SEARCH_PROPERTIES = 'ADMIN_SET_URL_SEARCH_PROPERTIES'
export const setUrlSearchProperties = (searchParams) => {
  return action (SET_URL_SEARCH_PROPERTIES, searchParams)
}

export const searchStudents = (searchString, searchBy) => {
  return action (ADMIN_SEARCH_STUDENTS, {searchString,searchBy})
}

export const searchLocatorStudents = (searchString) => {
  return action (ADMIN_SEARCH_LOCATOR_STUDENTS, {searchString})
}

export const selectLocatorStudent = (selectedStudent) => {
  return action (ADMIN_SELECT_LOCATOR_STUDENT, {selectedStudent})
}

export const setStudentLocatorInfo = (info) => {
  return action (ADMIN_SET_LOCATOR_STUDENT_INFO, info)
}

export const setLocatorSchedulePickerDate = (date) => {  
  return action (ADMIN_SET_LOCATOR_SCHEDULE_PICKER_DATE, date)
}

export const setLocatorSchedulePeriods = (periods) => {  
  return action (ADMIN_SET_LOCATOR_SCHEDULE_PERIODS, periods)
}

export const resetSearchLocatorStudentsResponse = () => {
  return action (ADMIN_RESET_SEARCH_LOCATOR_STUDENTS_RESPONSE)
}

export const requestSyncQueues = () => {
  return action (ADMIN_REQUEST_SYNC_QUEUES)
}

export const setSyncQueuesResponse = (payload) => {
  return action (ADMIN_SET_SYNC_QUEUES_RESPONSE, payload)
}

export const searchStudentsResponse = (students) => {
  return action (ADMIN_SEARCH_STUDENTS_RESPONSE, students)
}

export const searchLocatorStudentsResponse = (students) => {
  return action (ADMIN_SEARCH_LOCATOR_STUDENTS_RESPONSE, students)
}

export const updateStudentSearchStatus = (payload) => {
  return action (ADMIN_STUDENTS_SEARCH_STATUS_MESSAGE, payload)
}

export const messagingFetch = () => {
  return action (ADMIN_MESSAGING_FETCH)
}

export const messagingFetchResponse = (payload) => {
  return action (ADMIN_MESSAGING_FETCH_RESPONSE, payload)
}


export const MESSAGING_EDIT_PREVIEW_CONTENT = 'ADMIN_MESSAGING_EDIT_PREVIEW_CONTENT'
export const messagingEditPreviewContent = (payload) => {
  return action (MESSAGING_EDIT_PREVIEW_CONTENT, payload)
}

export const MESSAGING_EDIT_PREVIEW_CONTENT_RESPONSE = 'ADMIN_MESSAGING_EDIT_PREVIEW_CONTENT_RESPONSE'
export const messagingEditPreviewContentResponse = (payload) => {
  return action (MESSAGING_EDIT_PREVIEW_CONTENT_RESPONSE, payload)
}

export const ADMIN_MESSAGING_EDIT_SAVE_CONTENT = 'ADMIN_MESSAGING_EDIT_SAVE_CONTENT'
export const messagingEditSaveContent = (payload) => {
  return action (ADMIN_MESSAGING_EDIT_SAVE_CONTENT, payload)
}

export const messagingEditSaveContentResponse = (payload) => {
  return action (ADMIN_MESSAGING_EDIT_SAVE_CONTENT_RESPONSE, payload)
}

export const messagingEditUpdateContent = (payload) => {
  return action (ADMIN_MESSAGING_EDIT_UPDATE_CONTENT, payload)
}

export const messagingEditUpdateContentStyle = (payload) => {

  return action (ADMIN_MESSAGING_EDIT_UPDATE_CONTENT_STYLE, payload)
}

export const messagingEditChooseContentMessage = (payload) => {
  return action (ADMIN_MESSAGING_EDIT_CHOOSE_CONTENT_MESSAGE, payload)
}

export const kioskDefaultConfigurationEdit = (payload) => {
  return action (ADMIN_KIOSK_DEFAULT_CONFIGURATION_EDIT, payload)
}

export const kioskDefaultConfigurationEditResponse = (payload) => {
  return action (ADMIN_KIOSK_DEFAULT_CONFIGURATION_EDIT_RESPONSE, payload)
}

export const kioskDefaultConfigurationEditSave = (payload) => {
  return action (ADMIN_KIOSK_DEFAULT_CONFIGURATION_EDIT_SAVE, payload)
}

export const kioskDefaultConfigurationForcePush = (payload) => {
  return action (ADMIN_KIOSK_DEFAULT_CONFIGURATION_FORCE_PUSH, payload)
}

export const KIOSK_INSTANCE_EDIT = 'ADMIN_KIOSK_INSTANCE_EDIT'
export const kioskInstanceEdit = (kioskId) => {
  return action (KIOSK_INSTANCE_EDIT, kioskId)
}

export const PPK_CONFIGURATION_EDIT_RESPONSE = 'ADMIN_PPK_DEFAULT_CONFIGURATION_EDIT_RESPONSE'
export const ppkConfigurationEditResponse = (payload) => {
  return action (PPK_CONFIGURATION_EDIT_RESPONSE, payload)
}

export const PPK_EDIT = 'ADMIN_PPK_EDIT'
export const ppkEdit = (payload) => {
  return action (PPK_EDIT, payload)
}

export const PPK_CONFIGURATION_EDIT_PROP_SET = 'ADMIN_PPK_CONFIGURATION_EDIT_PROP_SET'
export const ppkEditPropSet = (payload) => {
  return action (PPK_CONFIGURATION_EDIT_PROP_SET, payload)
}

export const PPK_CONFIGURATION_EDIT_SAVE = 'ADMIN_PPK_CONFIGURATION_EDIT_SAVE'
export const ppkEditSave = (payload) => {
  return action (PPK_CONFIGURATION_EDIT_SAVE, payload)
}

export const PPK_CONFIGURATION_EDIT_SAVE_RESPONSE = 'ADMIN_PPK_CONFIGURATION_EDIT_SAVE_RESPONSE'
export const ppkEditSaveResponse = (payload) => {
  return action (PPK_CONFIGURATION_EDIT_SAVE_RESPONSE, payload)
}

export const PPK_CONFIGURATION_EDIT_CLOSE = 'ADMIN_PPK_CONFIGURATION_EDIT_CLOSE'
export const ppkEditClose = (payload) => {
  return action (PPK_CONFIGURATION_EDIT_CLOSE, payload)
}

export const updateGeneralSetting = (payload) => {
  return action(ADMIN_UPDATE_GENERAL_SETTING, payload)
}

export const loginShowUI = (payload) => {
  return action(ADMIN_LOGIN_SHOW_UI, payload)
}

export const loginSend = (payload) => {
  return action(ADMIN_LOGIN_SEND, payload)
}

export const ADMIN_FORGOT_PASSWORD_SEND = 'ADMIN_FORGOT_PASSWORD_SEND'

export const forgotPasswordSend = (payload) => {
  return action(ADMIN_FORGOT_PASSWORD_SEND, payload)
}

export const logoutSend = () => {
  return action(ADMIN_LOGOUT_SEND)
}

export const userAccountSearchResponse = (payload=[]) => {
  return action(ADMIN_USER_ACCOUNT_SEARCH_RESPONSE, payload)
}

export const userAccountSearch = (searchString='', force=false) => {
  return action(ADMIN_USER_ACCOUNT_SEARCH, {searchString,force})
}

export const userAccountDelete = () => {
  return action(ADMIN_USER_ACCOUNT_DELETE)
}

export const userAccountEdit = (id=false) => {
  return action(ADMIN_USER_ACCOUNT_EDIT, id)
}

export const userAccountEditDone = () => {
  return action(ADMIN_USER_ACCOUNT_EDIT_DONE)
}

export const userAccountEditSave = () => {
  return action(ADMIN_USER_ACCOUNT_EDIT_SAVE)
}

export const userAccountEditSaveResponse = (payload) => {
  return action(ADMIN_USER_ACCOUNT_EDIT_SAVE_RESPONSE, payload)
}

export const userAccountEditUpdateProperty = (payload) => {
  return action(ADMIN_USER_ACCOUNT_EDIT_UPDATE_PROPERTY,payload)
}

export const ADMIN_USER_ACCOUNT_UPLOAD_UPDATE_PROPERTY = 'ADMIN_USER_ACCOUNT_UPLOAD_UPDATE_PROPERTY'

export const userAccountUploadUpdateProperty = (payload) => {
  return action(ADMIN_USER_ACCOUNT_UPLOAD_UPDATE_PROPERTY,payload)

}

export const comingSoon = (message=false) => {
  const msg = (typeof message === 'string')? `${message} coming soon!` : 'Feature coming soon.'
  return action(ADMIN_COMING_SOON, msg)
}
/*
export const ADMIN_HOME_PAGE_DATA_FETCH = 'ADMIN_HOME_PAGE_DATA_FETCH'

export const homePageDataFetch = () => {
  return action(ADMIN_HOME_PAGE_DATA_FETCH)
}

export const ADMIN_HOME_PAGE_DATA_FETCH_RESPONSE = 'ADMIN_HOME_PAGE_DATA_FETCH_RESPONSE'

export const homePageDataFetchResponse = (payload) => {
  return action(ADMIN_HOME_PAGE_DATA_FETCH_RESPONSE, payload)
}
*/
export const ADMIN_USER_ACCOUNT_CLOSE_UPLOADER = 'ADMIN_USER_ACCOUNT_CLOSE_UPLOADER'

export const userAccountCloseUploader = () => {
  return action(ADMIN_USER_ACCOUNT_CLOSE_UPLOADER)
}

export const ADMIN_USER_ACCOUNT_OPEN_UPLOADER = 'ADMIN_USER_ACCOUNT_OPEN_UPLOADER'

export const userAccountOpenUploader = () => {
  return action(ADMIN_USER_ACCOUNT_OPEN_UPLOADER)
}

export const ADMIN_USER_ACCOUNT_UPLOAD = 'ADMIN_USER_ACCOUNT_UPLOAD'

export const userAccountUpload = () => {
  return action(ADMIN_USER_ACCOUNT_UPLOAD)
}

export const ADMIN_EMAIL_TEST = 'ADMIN_EMAIL_TEST'
export const emailTest = (payload) => {
  return action(ADMIN_EMAIL_TEST, payload)
}

export const ADMIN_WORKER_JOB_EDIT = 'ADMIN_WORKER_JOB_EDIT'
export const workerJobEdit = (payload) => {
  return action(ADMIN_WORKER_JOB_EDIT, payload)
}

export const WORKER_JOB_FETCH_LOGS = 'ADMIN_WORKER_JOB_FETCH_LOGS'
export const workerJobFetchLogs = (payload) => {
  return action(WORKER_JOB_FETCH_LOGS, payload)
}

export const WORKER_JOB_VIEW_LOGS = 'ADMIN_WORKER_JOB_VIEW_LOGS'
export const workerJobViewLogs = (payload) => {
  return action(WORKER_JOB_VIEW_LOGS, payload)
}

export const WORKER_JOB_VIEW_LOGS_DONE = 'ADMIN_WORKER_JOB_VIEW_LOGS_DONE'
export const workerJobViewLogsDone = () => {
  return action(WORKER_JOB_VIEW_LOGS_DONE, {})
}


export const WORKER_JOB_VIEW_LOGS_TOGGLE_EXPAND_DETAIL = 'ADMIN_WORKER_JOB_VIEW_LOGS_TOGGLE_EXPAND_DETAIL'
export const workerJobViewLogsToggleExpandDetail = (payload) => {
  return action(WORKER_JOB_VIEW_LOGS_TOGGLE_EXPAND_DETAIL, payload)
}

export const ADMIN_WORKER_JOB_EDIT_UPDATE_PROPERTY = 'ADMIN_WORKER_JOB_EDIT_UPDATE_PROPERTY'
export const workerJobEditUpdateProperty = (payload) => {
  return action(ADMIN_WORKER_JOB_EDIT_UPDATE_PROPERTY, payload)
}

export const ADMIN_WORKER_JOB_EDIT_SAVE = 'ADMIN_WORKER_JOB_EDIT_SAVE'
export const workerJobEditSave = (payload) => {
  return action(ADMIN_WORKER_JOB_EDIT_SAVE, payload)
}

export const ADMIN_WORKER_JOB_EDIT_SAVE_RESPONSE = 'ADMIN_WORKER_JOB_EDIT_SAVE_RESPONSE'
export const workerJobEditSaveResponse = (payload) => {
  return action(ADMIN_WORKER_JOB_EDIT_SAVE_RESPONSE, payload)
}

export const ADMIN_WORKER_JOB_EDIT_DONE = 'ADMIN_WORKER_JOB_EDIT_DONE'
export const workerJobEditDone = (payload) => {
  return action(ADMIN_WORKER_JOB_EDIT_DONE, payload)
}

export const ADMIN_WORKER_JOB_START_STOP = 'ADMIN_WORKER_JOB_START_STOP'
export const workerJobStartStop = (payload) => {
  return action(ADMIN_WORKER_JOB_START_STOP, payload)
}

export const SEARCH_FROM_URL = 'ADMIN_SEARCH_FROM_URL'
export const searchFromUrl = (pathChange=false) => {
  return action(SEARCH_FROM_URL, pathChange)
}

export const ADMIN_START_ALL_WORKER_JOBS_POLL = 'ADMIN_START_ALL_WORKER_JOBS_POLL'
export const startAllWorkerJobsPoll = (payload) => {
  return action(ADMIN_START_ALL_WORKER_JOBS_POLL, payload)
}

export const ADMIN_STOP_ALL_WORKER_JOBS_POLL = 'ADMIN_STOP_ALL_WORKER_JOBS_POLL'
export const stopAllWorkerJobsPoll = () => {
  return action(ADMIN_STOP_ALL_WORKER_JOBS_POLL)
}

export const START_HOME_PAGE_DATA_POLL = 'ADMIN_START_HOME_PAGE_DATA_POLL'
export const startHomePageDataPoll = (payload) => {
  return action(START_HOME_PAGE_DATA_POLL, payload)
}

export const STOP_HOME_PAGE_POLL = 'ADMIN_STOP_HOME_PAGE_POLL'
export const stopHomePagePoll = () => {
  return action(STOP_HOME_PAGE_POLL)
}

export const FETCH_HOME_PAGE_DATA_RESPONSE = 'ADMIN_FETCH_HOME_PAGE_DATA_RESPONSE'
export const fetchHomePageDataResponse = (payload) => {
  return action(FETCH_HOME_PAGE_DATA_RESPONSE, payload)
}

export const REPORT_TYPES_FETCH = 'ADMIN_REPORT_TYPES_FETCH'
export const reportTypesFetch = () => {
  return action(REPORT_TYPES_FETCH)
}

export const REPORT_TYPES_FETCH_RESPONSE = 'ADMIN_REPORT_TYPES_FETCH_RESPONSE'
export const reportTypesFetchResponse = (payload) => {
  return action(REPORT_TYPES_FETCH_RESPONSE, payload)
}

export const REPORT_PAGE_EDIT_UPDATE_PROPERTY = 'ADMIN_REPORT_PAGE_EDIT_UPDATE_PROPERTY'
export const reportPageEditUpdateProperty = (payload) => {
  return action(REPORT_PAGE_EDIT_UPDATE_PROPERTY, payload)
}

export const REPORT_PAGE_FETCH_REPORT_BY_ID = 'ADMIN_REPORT_PAGE_FETCH_REPORT_BY_ID'
export const reportPageFetchReportById = (payload) => {
  return action(REPORT_PAGE_FETCH_REPORT_BY_ID, payload)
}

export const REPORT_PAGE_FETCH_REPORT_BY_ID_RESPONSE = 'ADMIN_REPORT_PAGE_FETCH_REPORT_BY_ID_RESPONSE'
export const reportPageFetchReportByIdResponse = (payload) => {
  return action(REPORT_PAGE_FETCH_REPORT_BY_ID_RESPONSE, payload)
}

export const DANGEROUS_UPDATE_GENERAL_PROPERTY = 'ADMIN_DANGEROUS_UPDATE_GENERAL_PROPERTY'
export const dangerousUpdateGeneralProperty = (payloadPropertyName, payloadPropertyVal) => {
  const payload = (typeof payloadPropertyName === 'object')? payloadPropertyName : {propertyName:payloadPropertyName, val:payloadPropertyVal }
  return action(DANGEROUS_UPDATE_GENERAL_PROPERTY, payload)
}

export const PIRATE_ADD_KIOSKS_TO_ALL_LOCATIONS = 'ADMIN_PIRATE_ADD_KIOSKS_TO_ALL_LOCATIONS'
export const pirateAddKiosksToAllLocations = () => {
  return action(PIRATE_ADD_KIOSKS_TO_ALL_LOCATIONS)
}

export const PIRATE_GENERATE_NEGATIVE_ATTENDANCE = 'ADMIN_PIRATE_GENERATE_NEGATIVE_ATTENDANCE'
export const pirateGenerateNegativeAttendance = (payloadPropertyName, payloadPropertyVal) => {
  const payload = (typeof payloadPropertyName === 'object')? payloadPropertyName : {propertyName:payloadPropertyName, val:payloadPropertyVal }
  return action(PIRATE_GENERATE_NEGATIVE_ATTENDANCE, payload)
}

export const PIRATE_UPDATE_GENERAL_PROPERTY = 'ADMIN_PIRATE_UPDATE_GENERAL_PROPERTY'
export const pirateUpdateGeneralProperty = (payloadPropertyName, payloadPropertyVal) => {
  const payload = (typeof payloadPropertyName === 'object')? payloadPropertyName : {propertyName:payloadPropertyName, val:payloadPropertyVal }
  return action(PIRATE_UPDATE_GENERAL_PROPERTY, payload)
}


export const DANGEROUS_UPDATE_CLEAR_GENERAL_GENERAL_PROPERTY_COOKIES = 'ADMIN_DANGEROUS_UPDATE_CLEAR_GENERAL_GENERAL_PROPERTY_COOKIES'
export const dangerousUpdateClearGeneralPropertyCookies = () => {
  return action(DANGEROUS_UPDATE_CLEAR_GENERAL_GENERAL_PROPERTY_COOKIES, {})
}

export const DANGEROUS_PIRATE_ACTION_DELETE_ALL_ATTENDANCE_FROM_DB = 'ADMIN_DANGEROUS_PIRATE_ACTION_DELETE_ALL_ATTENDANCE_FROM_DB'
export const dangerousPirateDeleteAllAttendanceFromDB = () => {
  return action(DANGEROUS_PIRATE_ACTION_DELETE_ALL_ATTENDANCE_FROM_DB, {})
}

export const DANGEROUS_PIRATE_ACTION_DELETE_POSITIVE_ATTENDANCE_FROM_DB = 'ADMIN_DANGEROUS_PIRATE_ACTION_DELETE_POSITIVE_ATTENDANCE_FROM_DB'
export const dangerousPirateDeletePositiveAttendanceFromDB = () => {
  return action(DANGEROUS_PIRATE_ACTION_DELETE_POSITIVE_ATTENDANCE_FROM_DB, {})
}

export const PIRATE_MARK_NEGATIVE_ATTENDANCE_FOR_DELETION_FROM_Q = 'ADMIN_PIRATE_MARK_NEGATIVE_ATTENDANCE_FOR_DELETION_FROM_Q'
export const pirateMarkNegativeAttendanceForDeletionFromQ = () => {
  return action(PIRATE_MARK_NEGATIVE_ATTENDANCE_FOR_DELETION_FROM_Q, {})
}

export const DANGEROUS_PIRATE_ACTION_TRIGGER_DELETE_ALL_ATTENDANCE_FROM_Q = 'ADMIN_DANGEROUS_PIRATE_ACTION_TRIGGER_DELETE_ALL_ATTENDANCE_FROM_Q'
export const dangerousPirateTriggerDeleteAllAttendanceFromQ = () => {
  return action(DANGEROUS_PIRATE_ACTION_TRIGGER_DELETE_ALL_ATTENDANCE_FROM_Q, {})
}

export const DANGEROUS_PIRATE_ACTION_UNDELETE_SYNCED_ATTENDANCE_AND_MAKE_SYNC_NEEDED = 'ADMIN_DANGEROUS_PIRATE_ACTION_UNDELETE_SYNCED_ATTENDANCE_AND_MAKE_SYNC_NEEDED'
export const dangerousPirateUndeleteSyncedAndMakeSyncNeeded = () => {
  return action(DANGEROUS_PIRATE_ACTION_UNDELETE_SYNCED_ATTENDANCE_AND_MAKE_SYNC_NEEDED, {})
}

export const PIRATE_DELETE_NEGATIVE_ATTENDANCE_FROM_DB = 'ADMIN_PIRATE_DELETE_NEGATIVE_ATTENDANCE_FROM_DB'
export const pirateDeleteNegativeAttendanceFromDB = () => {
  return action(PIRATE_DELETE_NEGATIVE_ATTENDANCE_FROM_DB, {})
}

export const DANGEROUS_PIRATE_ACTION_GENERATE_POSITIVE_ATTENDANCE = 'ADMIN_DANGEROUS_PIRATE_ACTION_GENERATE_POSITIVE_ATTENDANCE'
export const dangerousPirateGeneratePositiveAttendance = (payload) => {
  return action(DANGEROUS_PIRATE_ACTION_GENERATE_POSITIVE_ATTENDANCE, payload)
}

export const DANGEROUS_PIRATE_ACTION_FETCH_DB_MOCKDATASETS = 'ADMIN_DANGEROUS_PIRATE_ACTION_FETCH_DB_MOCKDATA_SETS'
export const dangerousPirateFetchDbMockdatasets = (payload) => {
  return action(DANGEROUS_PIRATE_ACTION_FETCH_DB_MOCKDATASETS, payload)
}

export const DANGEROUS_PIRATE_ACTION_USE_DB_MOCKDATASET = 'ADMIN_DANGEROUS_PIRATE_ACTION_USE_DB_MOCKDATASET'
export const dangerousPirateFetchDbMockdataset = (payload) => {
  return action(DANGEROUS_PIRATE_ACTION_USE_DB_MOCKDATASET, payload)
}

export const DANGEROUS_PIRATE_ACTION_EXPORT_DB_MOCKDATASET = 'ADMIN_DANGEROUS_PIRATE_ACTION_EXPORT_DB_MOCKDATASET'
export const dangerousPirateExportDbMockdataset = (payload) => {
  return action(DANGEROUS_PIRATE_ACTION_EXPORT_DB_MOCKDATASET, payload)
}

export const DANGEROUS_PIRATE_ACTION = 'ADMIN_DANGEROUS_PIRATE_ACTION'
export const dangerousPirate = (action, payload) => {
  return action(DANGEROUS_PIRATE_ACTION, {action, payload})
}

export const HIDE_ADD_ENTITIES_DIALOG = 'HIDE_ADD_ENTITIES_DIALOG'
export const hideAddEntitiesDialog = (payload) => {
  return action(HIDE_ADD_ENTITIES_DIALOG, payload)
}

export const ADMIN_AT_MONITOR_COURSES_EDIT = 'ADMIN_AT_MONITOR_COURSES_EDIT';
export const attendanceMonitorCoursesEdit = (payload) => {
  return action(ADMIN_AT_MONITOR_COURSES_EDIT, payload);
};

export const ADMIN_AT_MONITOR_COURSES_SAVE = 'ADMIN_AT_MONITOR_COURSES_SAVE';
export const attendanceMonitorCoursesSave = (payload) => {
  return action(ADMIN_AT_MONITOR_COURSES_SAVE, payload);
};

export const ADMIN_AT_MONITOR_COURSES_SAVE_RESPONSE = 'ADMIN_AT_MONITOR_COURSES_SAVE_RESPONSE';
export const attendanceMonitorCoursesSaveResponse = (payload) => {
  return action(ADMIN_AT_MONITOR_COURSES_SAVE_RESPONSE, payload);
};

export const ADMIN_AT_MONITOR_COURSES_EDIT_DONE = 'ADMIN_AT_MONITOR_COURSES_EDIT_DONE';
export const attendanceMonitorCoursesEditDone = (payload) => {
  return action(ADMIN_AT_MONITOR_COURSES_EDIT_DONE, payload);
};

export const ADMIN_COURSES_FETCH = 'ADMIN_COURSES_FETCH';
export const coursesFetch = () => {
  return action(ADMIN_COURSES_FETCH);
};

export const ADMIN_COURSES_FETCH_RESPONSE = 'ADMIN_COURSES_FETCH_RESPONSE';
export const coursesFetchResponse = (data) => {
  return action(ADMIN_COURSES_FETCH_RESPONSE, data);
};

export const ADMIN_AT_MONITOR_CHOOSER_COURSES_RESPONSE = 'ADMIN_AT_MONITOR_CHOOSER_COURSES_RESPONSE';
export const attendanceMonitorChooserCoursesResponse = (data) => {
  return action(ADMIN_AT_MONITOR_CHOOSER_COURSES_RESPONSE, data);
};

export const ADMIN_AT_MONITOR_COURSES_CHOOSER_SET_IS_ENABLED = 'ADMIN_AT_MONITOR_COURSES_CHOOSER_SET_IS_ENABLED';
export const attendanceMonitorCoursesChooserSetIsEnabled = (data) => {
  return action(ADMIN_AT_MONITOR_COURSES_CHOOSER_SET_IS_ENABLED, data);
};

export const ADMIN_LOCATIONS_FETCH = 'ADMIN_LOCATIONS_FETCH';
export const locationsFetch = () => {
  return action(ADMIN_LOCATIONS_FETCH);
};

export const ADMIN_LOCATIONS_FETCH_RESPONSE = 'ADMIN_LOCATIONS_FETCH_RESPONSE';
export const locationsFetchResponse = (data) => {
  return action(ADMIN_LOCATIONS_FETCH_RESPONSE, data);
};

export const ADMIN_AT_MONITOR_LOCATIONS_EDIT = 'ADMIN_AT_MONITOR_LOCATIONS_EDIT';
export const attendanceMonitorLocationsEdit = (payload) => {
  return action(ADMIN_AT_MONITOR_LOCATIONS_EDIT, payload);
};

export const ADMIN_AT_MONITOR_LOCATIONS_EDIT_DONE = 'ADMIN_AT_MONITOR_LOCATIONS_EDIT_DONE';
export const attendanceMonitorLocationsEditDone = (payload) => {
  return action(ADMIN_AT_MONITOR_LOCATIONS_EDIT_DONE, payload);
};

export const ADMIN_AT_MONITOR_CHOOSER_LOCATIONS_RESPONSE = 'ADMIN_AT_MONITOR_CHOOSER_LOCATIONS_RESPONSE';
export const attendanceMonitorChooserLocationsResponse = (data) => {
  return action(ADMIN_AT_MONITOR_CHOOSER_LOCATIONS_RESPONSE, data);
};

export const ADMIN_AT_MONITOR_LOCATIONS_CHOOSER_SET_IS_ENABLED = 'ADMIN_AT_MONITOR_LOCATIONS_CHOOSER_SET_IS_ENABLED';
export const attendanceMonitorLocationsChooserSetIsEnabled = (data) => {
  return action(ADMIN_AT_MONITOR_LOCATIONS_CHOOSER_SET_IS_ENABLED, data);
};

export const ADMIN_AT_MONITOR_LOCATIONS_SAVE = 'ADMIN_AT_MONITOR_LOCATIONS_SAVE';
export const attendanceMonitorLocationsSave = (payload) => {
  return action(ADMIN_AT_MONITOR_LOCATIONS_SAVE, payload);
};

export const ADMIN_AT_MONITOR_LOCATIONS_SAVE_RESPONSE = 'ADMIN_AT_MONITOR_LOCATIONS_SAVE_RESPONSE';
export const attendanceMonitorLocationsSaveResponse = (payload) => {
  return action(ADMIN_AT_MONITOR_LOCATIONS_SAVE_RESPONSE, payload);
};

export const ADMIN_START_SYNC_SCHOOL_YEARS = 'ADMIN_START_SYNC_SCHOOL_YEARS';
export const startSyncSchoolYears = (payload) => {
  return action(ADMIN_START_SYNC_SCHOOL_YEARS, payload);
};

export const ADMIN_START_SCHOOL_YEARS_STATE_POLLING = 'ADMIN_START_SCHOOL_YEARS_STATE_POLLING';
export const startSyncSchoolYearsStatePolling = () => {
  return action(ADMIN_START_SCHOOL_YEARS_STATE_POLLING);
};

export const ADMIN_STOP_SCHOOL_YEARS_STATE_POLLING = 'ADMIN_STOP_SCHOOL_YEARS_STATE_POLLING';
export const stopSyncSchoolYearsStatePolling = (payload) => {
  return action(ADMIN_STOP_SCHOOL_YEARS_STATE_POLLING, payload);
};

export const ADMIN_SCHOOL_YEARS_FETCH = 'ADMIN_SCHOOL_YEARS_FETCH';
export const schoolYearsFetch = () => {
  return action(ADMIN_SCHOOL_YEARS_FETCH);
};

export const ADMIN_SCHOOL_YEARS_FETCH_RESPONSE = 'ADMIN_SCHOOL_YEARS_FETCH_RESPONSE';
export const schoolYearsFetchResponse = (data) => {
  return action(ADMIN_SCHOOL_YEARS_FETCH_RESPONSE, data);
};

export const ADMIN_MIGRATE_SCHOOL_YEAR = 'ADMIN_MIGRATE_SCHOOL_YEAR';
export const migrateSchoolYear = (payload) => {
  return action(ADMIN_MIGRATE_SCHOOL_YEAR, payload);
};

export const ADMIN_MIGRATE_SCHOOL_YEAR_RESPONSE = 'ADMIN_MIGRATE_SCHOOL_YEAR_RESPONSE';
export const migrateSchoolYearResponse = (data) => {
  return action(ADMIN_MIGRATE_SCHOOL_YEAR_RESPONSE, data);
};

export const ADMIN_MIGRATE_SCHOOL_YEAR_ERROR = 'ADMIN_MIGRATE_SCHOOL_YEAR_ERROR';
export const migrateSchoolYearError = (data) => {
  return action(ADMIN_MIGRATE_SCHOOL_YEAR_ERROR, data);
};

export const ADMIN_KIOSK_FACILITIES_CUSTOM_SETUP_EDIT_DONE = 'ADMIN_KIOSK_FACILITIES_CUSTOM_SETUP_EDIT_DONE';
export const kioskFacilitiesCustomSetupEditDone = (payload) => {
  return action(ADMIN_KIOSK_FACILITIES_CUSTOM_SETUP_EDIT_DONE, payload);
};

export const ADMIN_KIOSK_FACILITIES_CUSTOM_SETUP_PPK_EDIT = 'ADMIN_KIOSK_FACILITIES_CUSTOM_SETUP_PPK_EDIT';
export const kioskFacilitiesCustomSetupPPKEdit = (payload) => {
  return action(ADMIN_KIOSK_FACILITIES_CUSTOM_SETUP_PPK_EDIT, payload);
};

export const ADMIN_KIOSK_FACILITIES_CUSTOM_SETUP_WEB_EDIT = 'ADMIN_KIOSK_FACILITIES_CUSTOM_SETUP_WEB_EDIT';
export const kioskFacilitiesCustomSetupWebEdit = (payload) => {
  return action(ADMIN_KIOSK_FACILITIES_CUSTOM_SETUP_WEB_EDIT, payload);
};

export const ADMIN_KIOSK_FACILITIES_CUSTOM_SETUP_UPDATE_PROPERTY = 'ADMIN_KIOSK_FACILITIES_CUSTOM_SETUP_UPDATE_PROPERTY';
export const kioskFacilitiesCustomSetupUpdateProperty = (payload) => {
  return action(ADMIN_KIOSK_FACILITIES_CUSTOM_SETUP_UPDATE_PROPERTY, payload);
};

export const ADMIN_KIOSK_FACILITIES_CUSTOM_SETUP_APPLY = 'ADMIN_KIOSK_FACILITIES_CUSTOM_SETUP_APPLY';
export const kioskFacilitiesCustomSetupApply = (payload) => {
  return action(ADMIN_KIOSK_FACILITIES_CUSTOM_SETUP_APPLY, payload);
};

export const ADMIN_KIOSK_FACILITIES_CUSTOM_SETUP_UPDATE_EDITOR_PROPERTY = 'ADMIN_KIOSK_FACILITIES_CUSTOM_SETUP_UPDATE_EDITOR_PROPERTY';
export const kioskFacilitiesCustomSetupUpdateEditorProperty = (payload) => {
  return action(ADMIN_KIOSK_FACILITIES_CUSTOM_SETUP_UPDATE_EDITOR_PROPERTY, payload);
};


export const ADMIN_OVERRIDABLE_AT_TYPES_EDIT_DONE = 'ADMIN_OVERRIDABLE_AT_TYPES_EDIT_DONE';
export const overridableAtTypesEditDone = (payload) => {
  return action(ADMIN_OVERRIDABLE_AT_TYPES_EDIT_DONE, payload);
};

export const ADMIN_OVERRIDABLE_AT_TYPES_SAVE = 'ADMIN_OVERRIDABLE_AT_TYPES_SAVE';
export const overridableAtTypesSave = (payload) => {
  return action(ADMIN_OVERRIDABLE_AT_TYPES_SAVE, payload);
};

export const ADMIN_OVERRIDABLE_AT_TYPES_RESPONSE = 'ADMIN_OVERRIDABLE_AT_TYPES_RESPONSE';
export const overridableAtTypesSaveResponse = (payload) => {
  return action(ADMIN_OVERRIDABLE_AT_TYPES_RESPONSE, payload);
};

export const ADMIN_OVERRIDABLE_AT_TYPES_CHOOSER_SET_IS_ENABLED = 'ADMIN_OVERRIDABLE_AT_TYPES_CHOOSER_SET_IS_ENABLED';
export const overridableAtTypesChooserSetIsEnabled = (data) => {
  return action(ADMIN_OVERRIDABLE_AT_TYPES_CHOOSER_SET_IS_ENABLED, data);
};

export const ADMIN_OVERRIDABLE_AT_TYPES_EDIT = 'ADMIN_OVERRIDABLE_AT_TYPES_EDIT';
export const overridableAtTypesEdit = (payload) => {
  return action(ADMIN_OVERRIDABLE_AT_TYPES_EDIT, payload);
};

export const ADMIN_SEMESTER_RULE_FETCH_BY_FACILITY_ID = 'ADMIN_SEMESTER_RULE_FETCH_BY_FACILITY_ID';
export const semesterRuleFetchByFaciltyId = (payload) => {
  return action(ADMIN_SEMESTER_RULE_FETCH_BY_FACILITY_ID, payload);
};

export const ADMIN_SEMESTER_RULE_FETCH_BY_FACILITY_ID_RESPONSE = 'ADMIN_SEMESTER_RULE_FETCH_BY_FACILITY_ID_RESPONSE';
export const semesterRuleFetchByFaciltyIdResponse = (payload) => {
  return action(ADMIN_SEMESTER_RULE_FETCH_BY_FACILITY_ID_RESPONSE, payload);
};

export const ADMIN_SEMESTER_RULE_EDIT = 'ADMIN_SEMESTER_RULE_EDIT';
export const semesterRuleEdit = (payload) => {
  return action(ADMIN_SEMESTER_RULE_EDIT, payload);
};

export const ADMIN_SEMESTER_RULE_EDIT_DONE = 'ADMIN_SEMESTER_RULE_EDIT_DONE';
export const semesterRuleEditDone = (payload) => {
  return action(ADMIN_SEMESTER_RULE_EDIT_DONE, payload);
};

export const ADMIN_SEMESTER_RULE_EDIT_SAVE = 'ADMIN_SEMESTER_RULE_EDIT_SAVE';
export const semesterRuleEditSave = (payload) => {
  return action(ADMIN_SEMESTER_RULE_EDIT_SAVE, payload);
};

export const ADMIN_SEMESTER_RULE_EDIT_SAVE_RESPONSE = 'ADMIN_SEMESTER_RULE_EDIT_SAVE_RESPONSE';
export const semesterRuleEditSaveResponse = (payload) => {
  return action(ADMIN_SEMESTER_RULE_EDIT_SAVE_RESPONSE, payload);
};

export const ADMIN_ATTENDANCE_TERM_SET_IS_COUNT_RESET_ENABLED = 'ADMIN_ATTENDANCE_TERM_SET_IS_COUNT_RESET_ENABLED';
export const attendanceTermSetIsCountResetEnabled = (payload) => {
  return action(ADMIN_ATTENDANCE_TERM_SET_IS_COUNT_RESET_ENABLED, payload);
};

export const ADMIN_CONNECT_SKYWARD_ACCOUNT = 'ADMIN_CONNECT_SKYWARD_ACCOUNT'
export const adminConnectSkywardAccount = () => {
  return action(ADMIN_CONNECT_SKYWARD_ACCOUNT)
}

export const ADMIN_REMOVE_SKYWARD_ACCOUNT = 'ADMIN_REMOVE_SKYWARD_ACCOUNT'
export const adminRemoveSkywardAccount = () => {
  return action(ADMIN_REMOVE_SKYWARD_ACCOUNT)
}

export const ADMIN_REMOVE_SKYWARD_STATE = 'ADMIN_REMOVE_SKYWARD_STATE'
export const adminRemoveSkywardState = () => {
  return action(ADMIN_REMOVE_SKYWARD_STATE)
}

export const ADMIN_ENABLE_POSITIVE_ATTENDANCE = 'ADMIN_ENABLE_POSITIVE_ATTENDANCE'
export const enablePositiveAttendance = () => {
  return action(ADMIN_ENABLE_POSITIVE_ATTENDANCE)
}

export const ADMIN_ENABLE_POSITIVE_ATTENDANCE_RESPONSE = 'ADMIN_ENABLE_POSITIVE_ATTENDANCE_RESPONSE'
export const enablePositiveAttendanceResponse = (payload) => {
  return action(ADMIN_ENABLE_POSITIVE_ATTENDANCE_RESPONSE, payload)
}

export const ADMIN_ENABLE_PPK = 'ADMIN_ENABLE_PPK'
export const enablePPK = () => {
  return action(ADMIN_ENABLE_PPK)
}

export const ADMIN_ENABLE_PPK_RESPONSE = 'ADMIN_ENABLE_PPK_RESPONSE'
export const enablePPKResponse = (payload) => {
  return action(ADMIN_ENABLE_PPK_RESPONSE, payload)
}